import React from "react";

//bootstrap
import { Container } from "react-bootstrap";

const Resume = () => {
  return (
    <>
      <Container className="bg-dark py-2">
        <h4 className="text-light">Resume</h4>
      </Container>
    </>
  );
};

export default Resume;
