export const Slide0 = {
  src: require("./watch-time-splash.png"),
  title: "WATCHTIME",
  caption: "Build your movie bucket list",
  tech: ["MongoDB", "Express.js", "React.js", "Node.js"],
  links: [
    {
      type: "github",
      url: "www.gabecordova2.dev",
    },
    {
      type: "appUrl",
      url: "www.gabecordova3.dev",
    },
  ],
};

export const Slide1 = {
  src: require("./time-2-work-splash.jpeg"),
  title: "Time 2 Work",
  caption: "A quick and easy time and pay tracker",
  tech: ["MySQL", "Sequelize", "Express", "Bulma"],
  links: [
    {
      type: "github",
      url: "www.gabecordova4.dev",
    },
    {
      type: "appUrl",
      url: "www.gabecordova5.dev",
    },
  ],
};

export const Slide2 = {
  src: require("./cyber-jam-trivia.jpg"),
  title: "Cyber Jam Trivia",
  caption: "Test your trivia skills",
  tech: ["HTML/CSS", "JavaScript", "Bulma", "Web API"],
  links: [
    {
      type: "github",
      url: "www.gabecordova6.dev",
    },
    {
      type: "appUrl",
      url: "www.gabecordova7.dev",
    },
  ],
};
